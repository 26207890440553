import React from 'react';
import styled from 'styled-components';
import {Section} from '@nib/layout';
import Heading from '@nib-components/heading';
import {Footer, Layout, SEO} from '@nib/gatsby-theme-mesh-docs';

import Header from '../components/header/Header';
import Banner from '../components/banner';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35rem;
  min-height: 35vh;
`;

const NotFoundPage = () => (
  <Layout bannerContent={<Banner />}>
    <SEO />
    <Header />
    <Section>
      <ErrorWrapper>
        <Heading size={1}>404</Heading>
        <Heading size={2}>Page Not Found</Heading>
      </ErrorWrapper>
    </Section>
    <Footer />
  </Layout>
);

export default NotFoundPage;
